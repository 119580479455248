import React, { Component } from "react";

export const MContext = React.createContext();  //exporting context object

class MyProvider extends Component {
  state = { activeTab: "" }
  render() {
    return (
      <MContext.Provider value={
        {
          state: this.state,
          setActiveTab: (value) => this.setState({
            activeTab: value
          }),
          setMessage: (value) => this.setState({
            message: value
          })
        }}>
        {this.props.children}
      </MContext.Provider>)
  }
}
export default MyProvider;
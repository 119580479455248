import { MContext } from "./MyProvider";

class ThreadResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myTab: 'Threads',
      page: 1,
      isLoading: false,
      lastPage: false,
      results: [],
      search_text: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.results !== this.props.results) {
      this.setState({ results: this.props.results, search_text: this.props.search_text });
    }
  }

  render() {
    const {
      state: {
        lastPage,
        isLoading,
        results,
      }
    } = this;

    var data = results.map((item) => {

      return (
        <div class="brand-item" key={item.id}>
          <a href={"/" + item.forum_category_slug + "/" + item.slug} target='_blank'>

            <div class="icon-wrapper">
            </div>
            <div class="name-wrapper">
              {item.title}
            </div>

          </a>
        </div>

      )
    });

    return (

      <MContext.Consumer>
        {(context) => (
          !context.state.activeTab || context.state.activeTab === this.state.myTab ?
            (<div class='s-container'>
              <div class='container pb-section'>
                <h2>Threads</h2>
                <p>Matching “{this.props.search_text}”</p>

                <div class='brand-result'>
                  {data}
                </div>

              </div ></div>) : null
        )}
      </MContext.Consumer>
    )
  }

}

export default ThreadResult;

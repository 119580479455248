import { MContext } from "./MyProvider";
import NoDataIcon from '../images/no_data.jpeg'

class ListingCategoryResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      myTab: 'Listing Types'
    }
  }

  createLink = (item) => {
    let location = this.props.location
    let link = '/' + item.slug + '/' + location?.parent_slug + '/' + location?.slug
    return link
  }

  render() {
    const {
      createLink
    } = this;

    var data = this.props.results.map((item) => {
      return (
        <a class="link grid-item" key={item.id} href={createLink(item)}>
          <div class="fix-item">
            <div class="div-3">
              <div class="first-letter-icon">{item.name.charAt(0)}</div>
            </div>
            <div class="div-4">
              <div class="div-5">{item.name}</div>
            </div>
          </div>
        </a>
      )
    });

    return (
      <MContext.Consumer>
        {(context) => (
          !context.state.activeTab || context.state.activeTab === this.state.myTab ?
            (<div class='container pb-section'>
              <h2>Listing Category</h2>
              <p>Matching “{this.props.search_text}”</p>
              <div class='listing-category-result'>
                <div class='list-your-fix grid-container'>
                  {data}
                  {this.props.results.length == 0 ?
                    (<div className="no-data">
                      <img src={NoDataIcon}></img>
                    </div>) : null
                  }
                </div>
              </div>
            </div>) : null
        )}

      </MContext.Consumer>

    )
  }

}

export default ListingCategoryResult;
import ListingResult from "./search/ListingResult";
import ShowMeTab from "./search/ShowMeTab";
import ListingCategoryResult from './search/ListingCategoryResult'
import ProductResult from './search/ProductResult'
import BrandResult from "./search/BrandResult";
import NewsResult from "./search/NewsResult";
import MyProvider from "./search/MyProvider";
import CouponResult from "./search/CouponResult";
import ThreadResult from "./search/ThreadResult";

class SearchPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: ['Coupons Brands', 'Listing', 'Listing Types', 'Products', 'Threads'],
      activeTab: '',
      listings: [],
      listing_types: [],
      news: [],
      brands: [],
      products: [],
      coupons: [],
      threads: [],
    }
  }

  componentDidMount() {
    let search_text = this.props.search_text
    let location = this.props.location

    $.ajax({
      url: '/api/v2/search/search_multiple.json',
      method: "GET",
      data: { q: search_text, location_id: location?.id },
      dataType: "json",
      success: (data) => {
        this.setState({
          listings: data.listings,
          listing_types: data.listing_types,
          news: data.news,
          brands: data.brands,
          products: data.products,
          coupons: data.promo_websites,
          threads: data.threads,
        });
        console.log(data);
      }
    });
  }


  render() {
    const {
      state: {
        listings,
        listing_types,
        brands,
        products,
        news,
        coupons,
        threads,
      }
    } = this;

    return (
      <div>
        <MyProvider>
          <ShowMeTab />
          <CouponResult results={coupons} search_text={this.props.search_text} location={this.props.location} />
          <ListingResult results={listings} search_text={this.props.search_text} location={this.props.location} />
          <ListingCategoryResult results={listing_types} search_text={this.props.search_text} location={this.props.location} />
          <ProductResult results={products} search_text={this.props.search_text} />

          <ThreadResult results={threads} search_text={this.props.search_text} />
          {/* <BrandResult results={brands} search_text={this.props.search_text} /> */}
          {/* <NewsResult results={news} search_text={this.props.search_text} /> */}
        </MyProvider >
      </div >
    )
  }
}
export default SearchPage;

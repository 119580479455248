import { MContext } from "./MyProvider";

class BrandResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myTab: 'Brands',
      page: 1,
      isLoading: false,
      lastPage: false,
      results: [],
      search_text: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.results !== this.props.results) {
      this.setState({ results: this.props.results, search_text: this.props.search_text });
    }
  }

  loadMore = () => {
    this.setState((prevState) => ({
      page: prevState.page + 1
    }));

    const { search_text, page } = this.state

    $.ajax({
      url: '/api/v2/search/search_brand.json',
      method: "GET",
      data: { q: search_text, page: page },
      dataType: "json",
      success: (response) => {
        console.log(response)
        this.setState((prevState) => ({
          results: [...prevState.results, ...response.data.brands],
          lastPage: response.data.meta.last_page,
        }));
      }
    });

  };

  render() {
    const {
      loadMore,
      state: {
        lastPage,
        isLoading,
        results,
      }
    } = this;

    var data = results.map((item) => {
      return (
        <div class="brand-item" key={item.id}>
          <a href={'/brands/' + item.slug} target='_blank'>
            <div class="icon-wrapper">
              <img src={item.logo ? item.logo : '/assets/no-image-placeholder-medium.png'} alt="Avatar" />
            </div>
            <div class="name-wrapper">
              {item.name}
            </div>
          </a>
        </div>

      )
    });

    return (
      <MContext.Consumer>
        {(context) => (
          !context.state.activeTab || context.state.activeTab === this.state.myTab ?
            (<div class='brand-container'>
              <div class='container pb-section'>
                <h2>Brands</h2>
                <p>Matching “{this.props.search_text}”</p>

                <div class='brand-result'>
                  {data}
                </div>

                {!lastPage && results.length > 3 ?
                  (<div className="load-more">
                    <button onClick={loadMore} className="btn btn-light-border">
                      {isLoading ? 'Loading...' : 'Load More'}
                    </button>
                  </div>) : null
                }

              </div ></div>) : null
        )}
      </MContext.Consumer>
    )
  }

}

export default BrandResult;
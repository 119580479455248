import { MContext } from "./MyProvider";

class CouponResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myTab: 'Coupons Brands',
      page: 1,
      isLoading: false,
      lastPage: false,
      results: [],
      search_text: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.results !== this.props.results) {
      this.setState({ results: this.props.results, search_text: this.props.search_text });
    }
  }

  render() {
    const {
      state: {
        lastPage,
        isLoading,
        results,
      }
    } = this;

    var data = results.map((item) => {
      var url_slug = '';
      if (item.slug == null || item.slug == '')
        url_slug = item.hostname;
      else
        url_slug = item.slug;

      return (
        <div class="brand-item" key={item.id}>
          <a href={'/coupons/codes/' + url_slug} target='_blank'>
            <div class="icon-wrapper">
              <img src={item.image_url ? item.image_url : '/assets/no-image-placeholder-medium.png'} alt="Avatar" />
            </div>
            <div class="name-wrapper">
              {item.name}
            </div>
          </a>
        </div>

      )
    });

    return (

      <MContext.Consumer>
        {(context) => (
          !context.state.activeTab || context.state.activeTab === this.state.myTab ?
            (<div class='s-container'>
              <div class='container pb-section'>
                <h2>Coupons Brands</h2>
                <p>Matching “{this.props.search_text}”</p>

                <div class='brand-result'>
                  {data}
                </div>

              </div ></div>) : null
        )}
      </MContext.Consumer>
    )
  }

}

export default CouponResult;

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import OutsideClickHandler from 'react-outside-click-handler';

class PickLocationAutocomplete extends Component {
  timer;

  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
    selectedLocation: PropTypes.instanceOf(Object)
  };

  static defaultProps = {
    suggestions: [],
    selectedLocation: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",

      apiSuggestions: [],
    };

    this.refreshPageWhenLocationChange = this.refreshPageWhenLocationChange.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  componentDidMount() {
    let location = this.props.selectedLocation
    if (Object.keys(location).length != 0) {
      this.setState({
        userInput: location.name + ', ' + location.state_abbreviation
      });
    }
  }


  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;
    this.setState({ userInput: userInput })

    if (this.timer) {
      clearTimeout(this.timer)
    }

    this.timer = setTimeout(
      () =>
        $.ajax({
          url: '/find_cities.json',
          method: "POST",
          data: { q: userInput },
          dataType: "json",
          success: (data) => {
            // Filter our suggestions that don't contain the user's input
            const filteredSuggestions = data.locations.filter(
              suggestion =>
                suggestion.display_name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
            );

            this.setState({
              activeSuggestion: 0,
              filteredSuggestions,
              showSuggestions: true,
            });
          }
        })
      , 500)

  };

  setShowDropdown = (val) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: val,
    });
  }

  onClick = (e, location) => {
    let location_id = e.target.getAttribute('data-location-id')

    $.ajax({
      url: '/update_location.json',
      method: "POST",
      data: { location_id: location_id },
      dataType: "json",
      success: (data) => {
        if (data.location) {
          console.log('success', data)
          this.refreshPageWhenLocationChange(location)
        } else {
          console.log('error', data)
        }
      }
    });

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      });
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  refreshPageWhenLocationChange = location => {
    var currentController = $('#search-current-controller-hidden').val()
    var origin = window.location.origin;
    console.log('currentController', currentController);
    if (currentController == 'home#index') {
      window.location.replace(`${origin}/earth/${location.place_path}`)
    } else if (currentController == 'listings#index') {
      window.location.replace(`${origin}/listings/in/${location.place_path}`)
    } else if (currentController == 'brands#show') {
      window.location.reload();
    } else if (currentController == 'market#search') {
      window.location.reload();
    } else if (currentController == 'brands#retailers') {
      var path_name = document.location.pathname
      var arr = path_name.split(/\//);

      arr.splice(3, 1, location.parent_slug)
      arr.splice(4, 1, location.slug)
      var str_path = arr.join('/');
      window.location.replace(`${origin}${str_path}`)
    }
  }

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      setShowDropdown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <div class="suggestions-dropdown">

            <ul class="suggestions">
              <li class="link-to-search">
                <a href={"/market/search?q=" + userInput}>Current location
                </a>
              </li>

              {filteredSuggestions.map((suggestion, index) => {
                let className;

                // Flag the active suggestion with a class
                if (index === activeSuggestion) {
                  className = "suggestion-active";
                }

                return (
                  <li className={className} key={suggestion.id} onClick={e => onClick(e, suggestion)} data-location-id={suggestion.id}>
                    {suggestion.display_name}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      } else {
        suggestionsListComponent = (
          <div class="suggestions-dropdown">
            <ul class="suggestions">
              <li class="link-to-search">
                <a href={"/market/search?q=" + userInput}> Current location
                </a>
              </li>
            </ul>
          </div>
        );
      }
    }

    return (
      <OutsideClickHandler onOutsideClick={() => { setShowDropdown(false) }} className="haha">
        <div class="input-group search-location">
          <input
            type="text"
            class="form-control"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={userInput}
          />
          {suggestionsListComponent}
        </div>
      </OutsideClickHandler>
    );
  }
}

export default PickLocationAutocomplete;

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import React, { useState, useEffect } from "react";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

function MyEditor(props) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rawHtml, setRawHtml] = useState("");

  useEffect(() => {
    const html = props.content || '<p></p>'
    const editorState = htmlToDraftBlocks(html)
    setEditorState(editorState)
    setRawHtml(convertToRaw(editorState.getCurrentContent()))
  }, []);

  const htmlToDraftBlocks = (html) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
    setRawHtml(convertToRaw(editorState.getCurrentContent()))
  };

  return (
    <React.Fragment>
      <div>
        <Editor
          editorStyle={{ height: 200, margin: 12, borderWidth: 0.5, padding: 10, borderRadius: "2px" }}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        />
        <input name="store_product[description]" value={draftToHtml(rawHtml)} readOnly hidden></input>
      </div>
    </React.Fragment>
  )
}
export default MyEditor;
import React, { Component } from "react";
import PropTypes from "prop-types";
import OutsideClickHandler from 'react-outside-click-handler';

class SearchTextAutocomplete extends Component {
  timer;

  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: -1,
      // The suggestions that match the user's input
      filteredSuggestions: [],

      filteredListingSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",

      randomColors: ['bg-pattern-1', 'bg-pattern-2', 'bg-pattern-3'],
    };
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    this.setState({ userInput: userInput })
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(
      () =>
        $.ajax({
          url: '/api/v2/search/suggestions.json',
          method: "GET",
          data: { q: userInput },
          dataType: "json",
          success: (data) => {
            // Filter our suggestions that don't contain the user's input
            // const filteredSuggestions = data.locations.filter(
            //   suggestion =>
            //     suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
            // );
            const filteredSuggestions = data.listing_types
            const filteredListingTypeSuggestions = data.listing_types
            const filteredListingSuggestions = data.listings

            this.setState({
              activeSuggestion: -1,
              filteredSuggestions,
              filteredListingTypeSuggestions,
              filteredListingSuggestions,
              showSuggestions: true,
            });
          }
        })
      , 500)
  };

  onClickListing = (e, item) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: ''
    });
    window.location.href = '/listings/' + item.slug;
  };

  onClickListingType = (e, item) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
    let location = this.props.location;
    window.location.href = '/' + item.slug + '/' + location.parent_slug + '/' + location.slug
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions, userInput } = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      });

      window.location.href = '/market/search?q=' + userInput;
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  setShowDropdown = (val) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: val,
    });
  }

  render() {
    const {
      onChange,
      onClickListing,
      onClickListingType,
      onKeyDown,
      setShowDropdown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        filteredListingSuggestions,
        showSuggestions,
        userInput,
        randomColors,
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <div class="suggestions-dropdown">

            <ul class="suggestions">
              <li class="link-to-search">
                <a href={"/market/search?q=" + userInput}>See all results of "{userInput}"
                </a>
              </li>

              {filteredSuggestions.map((suggestion, index) => {
                let className;

                // Flag the active suggestion with a class
                if (index === activeSuggestion) {
                  className = "suggestion-active";
                }

                let listingTypeDefaultColor = randomColors[Math.floor(Math.random() * randomColors.length)];

                return (
                  <li className='suggestion-listing-item' key={suggestion.id} onClick={e => onClickListingType(e, suggestion)}>
                    <div class="item-icon">
                      <div class="item-icon-image-box">
                        {suggestion.icon ?
                          <img src={suggestion.icon} alt="Avatar" />
                          :
                          <div class={"listing-type-default-icon " + listingTypeDefaultColor}></div>
                        }
                      </div>
                    </div>

                    <div class="item-text">
                      <div>
                        {suggestion.name}
                      </div>
                    </div>
                  </li>
                );
              })}

              {filteredListingSuggestions.map((suggestion, index) => {
                let className;

                // Flag the active suggestion with a class
                if (index === activeSuggestion) {
                  className = "suggestion-active";
                }

                return (
                  <li className='suggestion-listing-item' key={suggestion.id} onClick={e => onClickListing(e, suggestion)}>

                    <div class="item-icon">
                      <div class="item-icon-image-box">
                        <img src={suggestion.avatar ? suggestion.avatar : "/assets/search/icon.png"} alt="Avatar" />
                      </div>
                    </div>
                    <div class="item-text">
                      <div>
                        {suggestion.name}
                      </div>
                      <div class='item-text-location'>
                        <small>
                          {suggestion.city}, {suggestion.state}
                        </small>
                      </div>
                    </div>

                  </li>
                );
              })}

            </ul>
          </div>
        );
      } else {
        suggestionsListComponent = (
          <div class="suggestions-dropdown">
            <ul class="suggestions">
              <li class="link-to-search">
                <a href={"/market/search?q=" + userInput}>See all results of "{userInput}"
                </a>
              </li>
            </ul>
          </div>
        );
      }
    }

    return (
      <OutsideClickHandler onOutsideClick={() => { setShowDropdown(false) }}>
        <div class="input-group search-text">
          <input
            type="text"
            class="form-control"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={userInput}
            placeholder="Search for a product, brand and more"
          />
          {suggestionsListComponent}
        </div>
      </OutsideClickHandler>
    );
  }
}

export default SearchTextAutocomplete;

import { MContext } from "./MyProvider";
import NoDataIcon from '../images/no_data.jpeg'

class ListingResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myTab: 'Listings',
      city: '',
      page: 1,
      isLoading: false,
      lastPage: false,
      results: [],
      search_text: '',
    }
  }

  createLink = (listing) => {
    return '/listings/' + listing.slug
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.results !== this.props.results) {
      this.setState({ results: this.props.results, search_text: this.props.search_text });
    }
  }

  componentDidMount() {
    let location = this.props.location
    this.setState({
      city: location?.name + ', ' + location?.state_abbreviation
    })
  }

  loadMore = () => {
    this.setState((prevState) => ({
      page: prevState.page + 1
    }));

    const { search_text, page } = this.state
    let location = this.props.location

    $.ajax({
      url: '/api/v2/search/search_listing.json',
      method: "GET",
      data: { q: search_text, page: page, location_id: location?.id },
      dataType: "json",
      success: (response) => {
        console.log(response)
        this.setState((prevState) => ({
          results: [...prevState.results, ...response.data.listings],
          lastPage: response.data.meta.last_page,
        }));
      }
    });

  };

  render() {
    const {
      createLink,
      loadMore,
      state: {
        city,
        lastPage,
        isLoading,
        results,
      }
    } = this;

    var data = results.map((item) => {
      return (
        <div key={item.id}>
          <div class="listing-item">
            <div class="listing-item-hoverable">
              <div class="listing-item-inside">
                <a href={createLink(item)}>
                  <div class="listing-flex">
                    <div class="icon-wrapper">
                      <div class="icon-inner">
                        <img src={item.avatar}></img>
                      </div>
                    </div>
                    <div class="detail-outer">
                      <div class="detail-wrapper">
                        <div class="detail-header">
                          <div class="name">
                            <h3>
                              {item.name}
                            </h3>
                          </div>
                          <div class="detail-distance">
                            <small>
                              {item.short_address} {item.distance ? " - " + item.distance + 'mi' : ""}
                            </small>
                          </div>
                        </div>
                        <div class="div-3">
                          <div class="rating-info">
                            <div class="listing-rating-small">
                              <div class="rating-large">
                                <i class={'star-img ' + item.stars_css_class}></i>
                              </div>
                              <span class="reviews-count">
                                ({item.reviews_count})
                              </span>
                            </div>
                          </div>
                        </div>

                        {item.open_until ? (<div class="div-4"> Open until #{close_time} </div>) : null}

                        <div class="div-5">
                          {
                            item.listing_types.map(elmt => (<div class="listing-type-item"><div class="small-tag">{elmt.name}</div></div>))
                          }
                        </div>
                        <div class="div-6">
                          <p>{item.top_review}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div >

        </div >
      )
    });

    return (
      <MContext.Consumer>
        {(context) => (
          !context.state.activeTab || context.state.activeTab === this.state.myTab ?
            (
              <div class='container pb-section'>
                <h4>All “{this.props.search_text}” results in {city}</h4>
                <p>Matching “{this.props.search_text}”</p>
                {data}

                {results.length == 0 ?
                  (<div className="no-data">
                    <img src={NoDataIcon}></img>
                  </div>) : null
                }

                {!lastPage && results.length > 2 ?
                  (<div className="load-more">
                    <button onClick={loadMore} className="btn btn-blue-button btn-lg btn-block">
                      {isLoading ? 'Loading...' : 'Load More'}
                    </button>
                  </div>) : null
                }

                <hr />
              </div>) : null
        )}
      </MContext.Consumer >
    )
  }

}

export default ListingResult;
import { MContext } from "./MyProvider";

class NewsResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myTab: 'Blog',
      page: 1,
      isLoading: false,
      lastPage: false,
      results: [],
      search_text: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.results !== this.props.results) {
      this.setState({ results: this.props.results, search_text: this.props.search_text });
    }
  }

  loadMore = () => {
    this.setState((prevState) => ({
      page: prevState.page + 1
    }));

    const { search_text, page } = this.state

    $.ajax({
      url: '/api/v2/search/search_news.json',
      method: "GET",
      data: { q: search_text, page: page },
      dataType: "json",
      success: (response) => {
        this.setState((prevState) => ({
          results: [...prevState.results, ...response.data.news],
          lastPage: response.data.meta.last_page,
        }));
      }
    });

  };

  render() {
    const {
      loadMore,
      state: {
        lastPage,
        isLoading,
        results,
      }
    } = this;

    var data = results.map((item) => {
      return (
        <React.Fragment key={item.id}>
          <div class="news-item">
            <a href={'/blog/' + item.post_name}>
              <div class="card">
                <div class="card-image">
                  <img alt="Card image cap" class="card-img-top img-responsive" src={item.featured_image_url} />
                </div>
                <div class="card-body">
                  <div class="div-1">
                    <div class="btn btn-primary">{item.first_category_name}</div>
                  </div>
                  <div class="div-2 text-truncated-2">{item.post_title}</div>
                  <div class="div-3 text-truncated-3">{item.post_excerpt}</div>
                  <div class="div-4">{item.formated_post_date}</div>
                </div>
              </div>
            </a>
          </div>
        </React.Fragment>
      )
    });

    return (
      <MContext.Consumer>
        {(context) => (
          !context.state.activeTab || context.state.activeTab === this.state.myTab ?
            (<div class='container pb-section'>
              <h2>News & Culture</h2>
              <p>Matching “{this.props.search_text}”</p>

              <div class='news-result'>
                {data}
              </div>

              {!lastPage && results.length > 2 ?
                (<div className="load-more">
                  <button onClick={loadMore} className="btn btn-light-border">
                    {isLoading ? 'Loading...' : 'Load More'}
                  </button>
                </div>) : null
              }

              <hr />
            </div >) : null
        )}
      </MContext.Consumer>
    )
  }

}

export default NewsResult;
// import TextInput from 'react-autocomplete-input'
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import SearchTextAutocomplete from "./SearchTextAutocomplete";
import PickLocationAutocomplete from "./PickLocationAutocomplete";

class SearchBar extends React.Component {
  static propTypes = {
    selectedLocation: PropTypes.instanceOf(Object)
  };

  static defaultProps = {
    selectedLocation: {}
  };

  constructor(props, context) {
    super(props, context);
    this.state = {

    }
  }

  componentDidMount() {
  }

  render() {


    return (
      <div class="searchbar">

        <span class="input-group-addon input-group-search" >
          <i class="glyphicon glyphicon-search"></i>
        </span>

        <SearchTextAutocomplete
          location={this.props.selectedLocation}
        />

        <span class="input-group-addon" >
          <i class="glyphicon glyphicon-map-marker"></i>
        </span>

        <PickLocationAutocomplete
          selectedLocation={this.props.selectedLocation}
        />


      </div >
    )
  }
}

export default SearchBar;
